import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { lazy, Suspense } from "react";
import Loader from "react-loader-spinner";
// import ProtectedRoute from "./ProtectedRoute";
import moment from "moment";
import $ from "jquery";

import Cookies from "js-cookie";

const PageNotFound = lazy(() => import("./components/common/PageNotFound"));

const AuthLogin = lazy(() => import("./components/common/AuthLogin"));

const Home = lazy(() => import("./components/Home/Home"));

const token = Cookies.get("AuthToken");

function App() {
  const auth = () => {
    if (Cookies.get("AuthToken")) {
      return true;
    } else {
      return false;
    }
  };

  const body = document.querySelector("body");
  body?.style.setProperty("--screen-height", $(window).height() + "px");

  const isAuthenticated = auth();

  return (
    <div className="App">
      <Suspense
        fallback={
          <center style={{ paddingTop: "40vh" }}>
            <Loader
              type="TailSpin"
              height={100}
              width={100}
              color="#32c5ff"
              secondaryColor="black"
              // timeout={3000} //3 secs
            />
          </center>
        }>
        <Router>
          <Switch>
            {/* <Route exact path="/" component={Home} /> */}
            <Route exact path="/Home/:surveyId" component={Home} />
            <Route exact path="/:companyName/Analytics/:surveyId" component={Home} />

            {/* <ProtectedRoute exact path="/Dashboard" component={Dashboard} isAuth={isAuthenticated} />
            <ProtectedRoute
              exact
              path="/EditOrganization/:orgId"
              component={EditOrganization}
              isAuth={isAuthenticated}
            /> */}
            <Route exact path="/:companyName/AnalyticsRequest/:surveyId" component={AuthLogin} />
            <Route exact path="/:companyName/Login/:surveyId" component={AuthLogin} />
            <Route component={AuthLogin} />

            <Route exact path="/PageNotFound" component={PageNotFound} />
            <Route component={PageNotFound} />
          </Switch>
        </Router>
      </Suspense>
    </div>
  );
}

export default App;
